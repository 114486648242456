export const flowplayerToken =
    'eyJraWQiOiJZRjk0UDlMM0phTzUiLCJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjIjoie1wiYWNsXCI6NixcImlkXCI6XCJZRjk0UDlMM0phTzVcIn0iLCJpc3MiOiJGbG93cGxheWVyIn0.GwJk8dFr3gmm7wBniq0FjDGKq2p6B9OEnYYBKG-roi6SjcF7G8ljbXmEfwwRWDmWNY_3oMJEuun3w-UGUQWb0g';

export const allLanguages: { key: string; name: string; desc: string }[] = [
    { key: 'en', name: 'English', desc: 'English' },
    { key: 'de', name: 'Deutsch', desc: 'German' },
    { key: 'ru', name: 'Русский', desc: 'Russian' },
    { key: 'fr', name: 'Français', desc: 'French' },
    { key: 'es', name: 'Español', desc: 'Spanish' },
    { key: 'pt-br', name: 'Português', desc: 'Portuguese (Brazilian)' },
    { key: 'tr', name: 'Türkçe', desc: 'Turkish' },
    { key: 'it', name: 'Italiano', desc: 'Italian' },
    { key: 'hb', name: 'עִבְרִית', desc: 'Hebrew' },
    { key: 'nl', name: 'Nederlands', desc: 'Dutch' },
    { key: 'ja', name: '日本語', desc: 'Japanese' },
    { key: 'kr', name: '한국어', desc: 'Korean' },
    { key: 'cn', name: '中文', desc: 'Chinese' },
    { key: 'ar', name: 'العربية', desc: 'Arabic' },
    { key: 'hi', name: 'हिंदी', desc: 'Hindi' },
    { key: 'no', name: 'norsk', desc: 'Norwegian' },
    { key: 'sv', name: 'svenska', desc: 'Swedish' },
    { key: 'da', name: 'dansk', desc: 'Danish' },
    { key: 'pl', name: 'Polski', desc: 'Polish' },
];

export const translationApis: { key: string, name: string, default?: boolean|null }[] = [
    { key: 'chatgpt', name: 'ChatGPT', default: true }
]

export const editorsEmail: { key: string, name: string, default?: boolean|null }[] = [  
    { key: 'benjaminwerth', name: 'benjamin.werth@jove.com' },
    { key: 'ethanzhang', name: 'ethan.zhang@jove.com' },
    { key: 'sourikmondal', name: 'sourik.mondal@jove.com' },
    { key: 'balamurugankuppan', name: 'balamurugan.kuppan@jove.com' },
    { key: 'ednageorge', name: 'edna.george@jove.com' },
    { key: 'shalinisanyal', name: 'shalini.sanyal@jove.com' },
    { key: 'nupurfangaria', name: 'nupur.fangaria@jove.com' },
    { key: 'neethuabraham', name: 'neethu.abraham@jove.com' },
    { key: 'jasmeetsahni', name: 'jasmeet.sahni@jove.com' },
    { key: 'shivanibhagat', name: 'shivani.bhagat@jove.com' },
    { key: 'tasaduqwani', name: 'tasaduq.wani@jove.com' },
    { key: 'adityaroy', name: 'aditya.roy@jove.com' },
    { key: 'titusthankachan', name: 'titus.thankachan@jove.com' },
    { key: 'dharasachdev', name: 'dhara.sachdev@jove.com' },
    { key: 'ashnaali', name: 'ashna.ali@jove.com' },
    { key: 'keertichauhan', name: 'keerti.chauhan@jove.com' },
]

export const translationApiVer: { key: string, name: string, default?: boolean|null }[] = [
    { key: 'gpt-3.5-turbo-16k', name: 'GPT-3.5 [16k] (Recommended)', default: true },
    { key: 'gpt-4', name: 'GPT-4 [8k]' }
]