import { axios } from '@/lib/axios';
import { QuizzesResponse, CreateQuizData, UpdateQuizData, QuizLibraryResponse, ImportPlaylistResponse, QuizLibrary, ShareResponse } from '../types';

import { EndDate, Quiz, RebuildSectionQuizLibraryData, UserResultsResponse } from '@/types';

import { GetListBodyStructure } from '@/types';
import { API_URL } from '@/config';
import FileDownload from 'js-file-download';

export const buildQuizzesParams = (
    activePage: number,
    activeSearchKeyword: string | number,
    withAssign: boolean | null = false,
    itemsOnPage = 20
) => {
    return {
        navigation: {
            page: activePage,
            itemsOnPage,
        },
        totalCount: true,
        filter: {
            collection: [
                ...(activeSearchKeyword
                    ? [
                          {
                              bool_operator: 'or',
                              condition: {
                                  property: Number.isInteger(activeSearchKeyword) ? 'id' : 'title',
                                  operator: Number.isInteger(activeSearchKeyword) ? 'equals' : 'contains',
                                  value: Number.isInteger(activeSearchKeyword) ? activeSearchKeyword : activeSearchKeyword || '',
                              },
                          },
                      ]
                    : []),
            ],
        },
        sort: [['id', 'desc']],
        withAssign,
    };
};

export const postQuizzes = (data: GetListBodyStructure): Promise<QuizzesResponse> => {
    return axios.post(`${API_URL}/api/admin/quiz/template_list`, data);
};

export const getQuiz = (id: number): Promise<Quiz> => {
    return axios.get(`${API_URL}/api/admin/quiz/template/${id}`);
};

export const removeTemplateQuiz = (id: number): Promise<void> => {
    return axios.delete(`${API_URL}/api/admin/quiz/template/${id}`);
};

export const createQuiz = (data: CreateQuizData): Promise<Quiz> => {
    return axios.post(`${API_URL}/api/admin/quiz/template/create`, data);
};

export const updateQuiz = (id: number, data: UpdateQuizData): Promise<Quiz> => {
    return axios.patch(`${API_URL}/api/admin/quiz/template/${id}`, data);
};

export const saveQuizImage = (data: FormData): Promise<{ url: string }> => {
    return axios.post(`${API_URL}/api/quiz/upload_image`, data);
};

export const uploadQuizQti = (data: FormData) => {
    return axios.post(`${API_URL}/api/admin/quiz/template/from_package/qti`, data);
};

export const getQuizTemplateByArticleId = (id: number): Promise<Quiz> => {
    return axios.get(`${API_URL}/api/admin/quiz/from_article/${id}`);
};

export const getQuizLibraryById = (id: number): Promise<QuizLibrary> => {
    return axios.get(`${API_URL}/api/admin/quiz/library/${id}`);
};

export const removeQuizLibraryById = (id: number): Promise<void> => {
    return axios.delete(`${API_URL}/api/admin/quiz/library/${id}`);
};

export const createQuizLibrary = (data: CreateQuizData): Promise<QuizLibrary> => {
    return axios.post(`${API_URL}/api/admin/quiz/library/create`, data);
};

export const updateQuizLibrary = (id: number, data: UpdateQuizData): Promise<QuizLibrary> => {
    return axios.patch(`${API_URL}/api/admin/quiz/library/${id}`, data);
};

export const getQuizLibrary = (data: GetListBodyStructure): Promise<QuizLibraryResponse> => {
    return axios.post(`${API_URL}/api/admin/quiz/library_quiz_list`, data);
};

export const getQuizDataByPlaylistId = (id: number): Promise<ImportPlaylistResponse> => {
    return axios.get(`${API_URL}/api/admin/quiz/from_playlist/${id}`);
};

export const getSharedQuizzes = (data: GetListBodyStructure): Promise<QuizLibraryResponse> => {
    return axios.post(`${API_URL}/api/admin/quiz/shared_quiz_list`, data);
};

export const getSharedUserQuizzes = (): Promise<QuizLibrary[]> => {
    return axios.get(`${API_URL}/api/admin/quiz/list_shared_by_me`);
};

export const getMyQuizzes = (data: GetListBodyStructure): Promise<QuizLibraryResponse> => {
    return axios.post(`${API_URL}/api/admin/quiz/user_quiz_list`, data);
};
export const getAdminQuizzes = (data: GetListBodyStructure): Promise<QuizLibraryResponse> => {
    return axios.post(`${API_URL}/api/admin/quiz/quiz_list`, data);
};

export const deleteAdminQuizById = (id: number): Promise<void> => {
    return axios.delete(`${API_URL}/api/admin/quiz/${id}`);
};

export const getSharedUsersAdminQuizById = (
    id: number
): Promise<{
    id: number;
    sharedList?: {
        user: {
            id: number;
            email: string;
            firstName: string;
            lastName: string;
        };
    }[];
    assignList?: {
        user: {
            id: number;
            email: string;
            firstName: string;
            lastName: string;
        };
    }[];
}> => {
    return axios.get(`${API_URL}/api/admin/quiz/shared_with/${id}`);
};

export const saveSharedUsersAdminQuizById = (
    id: number,
    data: {
        emails: string[];
        isAdminQuiz?: boolean;
    }
): Promise<ShareResponse> => {
    return axios.post(`${API_URL}/api/admin/quiz/share/${id}`, data);
};

export const getAdminQuizById = (id: number): Promise<QuizLibrary> => {
    return axios.get(`${API_URL}/api/admin/quiz/${id}`);
};

export const createAdminQuiz = (data: CreateQuizData): Promise<QuizLibrary> => {
    return axios.post(`${API_URL}/api/admin/quiz/create`, data);
};

export const updateAdminQuiz = (id: number, data: UpdateQuizData): Promise<QuizLibrary> => {
    return axios.patch(`${API_URL}/api/admin/quiz/${id}`, data);
};

export const getUserQuizById = (id: number): Promise<QuizLibrary> => {
    return axios.get(`${API_URL}/api/admin/quiz/user_quiz/${id}`);
};

export const createUserQuiz = (data: CreateQuizData): Promise<QuizLibrary> => {
    return axios.post(`${API_URL}/api/quiz/create`, data);
};

export const updateUserQuiz = (id: number, data: UpdateQuizData): Promise<QuizLibrary> => {
    return axios.patch(`${API_URL}/api/admin/quiz/user_quiz/${id}`, data);
};

export const getCustomerResults = (id: string): Promise<Quiz> => {
    return axios.get(`${API_URL}/api/admin/quiz/user_quiz/${id}/assign`);
};

export const getCustomerUserResults = (id: string): Promise<UserResultsResponse> => {
    return axios.get(`${API_URL}/api/admin/quiz/assign/${id}`);
};

export const deleteQuiz = (id: number): Promise<void> => {
    return axios.delete(`${API_URL}/api/admin/quiz/user_quiz/${id}`);
};

export const getQuizResultCSV = (id: string): Promise<void> => {
    return axios.get(`${API_URL}/api/admin/quiz/user_quiz/${id}/assign?format=csv`, { responseType: 'blob' }).then((response) => {
        FileDownload(response.data, `${id}_results.csv`);
    });
};

export const updateEndDate = (id: number, data: EndDate): Promise<string> => {
    return axios.post(`${API_URL}/api/admin/quiz/set_end_date/${id}`, data);
};

export const rebuildQuizLibraryById = (id: number): Promise<QuizLibrary> => {
    return axios.get(`${API_URL}/api/admin/quiz/update_section_quiz_library/${id}`);
};

export const rebuildSectionQuizLibrary = (data: RebuildSectionQuizLibraryData): Promise<string> => {
    return axios.post(`${API_URL}/api/admin/quiz/process_section_quiz_libraries`, data);
};
