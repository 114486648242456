import { useAuth } from '@/context/AuthContext';
import React, { ReactNode, useState } from 'react';
import { Tr, Td, Text, useDisclosure, Flex, Box, Tooltip, Spinner } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { QuizLibrary } from '../../types';
import { DeleteQuizModal } from '@/features/Quiz/components/DeleteQuizModal';
import Icon from '@/components/Icon';

import { format } from 'date-fns';
import { useQti } from '@/api/qtiApi';
import InstitutionsModal from '@/features/Playlists/components/InstitutionsModal';
import { usePlaylistCC } from '@/api/ccApi';
import { InstitutionModalTypes } from '@/features/Playlists';
import { usePlaylistScorm } from '@/api/scormApi';

type Props = {
    quiz: QuizLibrary;
    isAdminQuiz: boolean;
};

const TextEl = ({ children }: { children: ReactNode }) => (
    <Text color="black" lineHeight={'21px'} textAlign="center" display="inline-block" p="0 10px" w={'auto'}>
        {children}
    </Text>
);

export const CustomerQuizItem = ({ quiz, isAdminQuiz }: Props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { user } = useAuth();
    const isSuperAdmin = user?.roles.includes('ROLE_SUPER_ADMIN');

    const { isOpen: isInstitutionsOpen, onOpen: onInstitutionsOpen, onClose: onInstitutionsClose } = useDisclosure();
    const [type, setType] = useState<InstitutionModalTypes>(null);

    const downloadType = 'from_quiz';

    const { isLoading: isQtiLoading, handleQtiDownload } = useQti({
        qtiType: downloadType,
        qtiId: quiz?.id ?? 0,
        title: quiz?.title ?? '',
    });

    const { isLoading: isCCLoading, handleCCDownload } = usePlaylistCC({
        ccType: downloadType,
        ccId: quiz?.id ?? 0,
        title: quiz?.title ?? '',
    });

    const { isLoading: isScormLoading, handleScormDownload } = usePlaylistScorm({
        scormType: downloadType,
        scormId: quiz?.id ?? 0,
        playlistTitle: quiz?.title ?? '',
    });

    const handleOpenInstitutionsCC = () => {
        setType('cc');
        onInstitutionsOpen();
    };

    const handleOpenInstitutionsScorm = () => {
        setType('scorm');
        onInstitutionsOpen();
    };

    const handleOpenInstitutionsQti = () => {
        setType('qti');
        onInstitutionsOpen();
    };

    return (
        <>
            <InstitutionsModal
                isOpen={isInstitutionsOpen}
                onClose={onInstitutionsClose}
                handleScormDownload={handleScormDownload}
                handleCCDownload={handleCCDownload}
                handleQtiDownload={handleQtiDownload}
                isLoading={isScormLoading || isCCLoading || isQtiLoading}
                type={type}
            />
            <DeleteQuizModal isOpen={isOpen} onClose={onClose} quiz={quiz} quizType={'customer'} />
            <Tr>
                <Td w={'65%'} py={'10px'}>
                    <Flex as={Link} to={`/quiz/customer-quiz/view/${quiz.id}`} py={'10px'}>
                        <Text color={'black'} lineHeight={'21px'}>
                            {quiz.title}
                        </Text>
                    </Flex>
                </Td>

                <Td w={'15%'} textAlign="center">
                    <TextEl>{quiz?.restrictNonInstitutional ? 'Restricted' : 'Open'}</TextEl>
                </Td>

                <Td w={'15%'} textAlign="center">
                    <TextEl>{`${quiz.createdBy?.firstName ?? ''} ${quiz.createdBy?.lastName ?? ''}`}</TextEl>
                </Td>
                <Td w={'15%'} textAlign="center">
                    <TextEl>{quiz?.createdBy?.email ?? '-'}</TextEl>
                </Td>
                <Td w={'15%'} textAlign="center">
                    <TextEl>{quiz.createdAt ? format(new Date(quiz.createdAt), 'MM/dd/yyyy') : '-'}</TextEl>
                </Td>
                <Td w={'15%'} textAlign="center">
                    <TextEl>{quiz?.endDate ? format(new Date(quiz.endDate), 'MM/dd/yyyy') : quiz?.endDate === null ? '-' : 'Expired'}</TextEl>
                </Td>
                <Td w="auto">
                    <Flex columnGap={'10px'} align="center" justifyContent={'space-around'}>
                        {isSuperAdmin && (
                            <>
                                <Box
                                    as={Link}
                                    to={quiz.editable ? `/quiz/customer-quiz/edit/${quiz.id}` : `/quiz/customer-quiz/copy/${quiz.id}`}
                                    borderRadius={'16px'}
                                    height={'28px'}>
                                    <Tooltip hasArrow label={quiz.editable ? 'Edit quiz' : 'Copy'} aria-label={quiz.editable ? 'Edit quiz' : 'Copy'}>
                                        <span>
                                            <Icon highlightOnHover icon={quiz.editable ? 'pencil' : 'clone'} />
                                        </span>
                                    </Tooltip>
                                </Box>
                            </>
                        )}
                        <Box onClick={handleOpenInstitutionsScorm}>
                            <Tooltip hasArrow label={'Download SCORM'} aria-label={'Download SCORM'}>
                                {isScormLoading ? (
                                    <Spinner size={'xs'} />
                                ) : (
                                    <span>
                                        <Icon highlightOnHover icon="file-zipper" />
                                    </span>
                                )}
                            </Tooltip>
                        </Box>
                        <Box as={Link} to={`/quiz/customer-quiz/results/${quiz.id}`} borderRadius={'16px'} height={'28px'}>
                            <Tooltip hasArrow label={'View Results'} aria-label={'View Results'}>
                                <span>
                                    <Icon highlightOnHover icon="chart-mixed" />
                                </span>
                            </Tooltip>
                        </Box>
                        <Box onClick={handleOpenInstitutionsQti}>
                            <Tooltip hasArrow label={'Download QTI'} aria-label={'Download QTI'}>
                                <span>
                                    <Icon fontSize={'20px'} highlightOnHover icon="scroll" />
                                </span>
                            </Tooltip>
                        </Box>
                        <Box onClick={handleOpenInstitutionsCC}>
                            <Tooltip hasArrow label={'Download CC'} aria-label={'Download CC'}>
                                {isCCLoading ? (
                                    <Spinner size={'xs'} />
                                ) : (
                                    <span>
                                        <Icon fontSize={'20px'} highlightOnHover icon="closed-captioning" />
                                    </span>
                                )}
                            </Tooltip>
                        </Box>
                        <Box onClick={onOpen} fontSize={'0.875rem'} color={'#cc0000'} fontWeight={500} cursor={'pointer'}>
                            <Tooltip hasArrow label={'Delete quiz'} aria-label={'Delete quiz'}>
                                <span>
                                    <Icon highlightOnHover icon="trash" />
                                </span>
                            </Tooltip>
                        </Box>
                    </Flex>
                </Td>
            </Tr>
        </>
    );
};
