import { useAuth } from '@/context/AuthContext';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Textarea,
    Text,
    Box,
} from '@chakra-ui/react';
import { QuizType } from '@/redux/reducers/quizzes/quizSlice';
import { useLocation } from 'react-router-dom';
import { AssignQuizToUsersRequest, SharedListAdminQuiz, SharedListMyQuiz } from '../../types';
import { useQuery } from 'react-query';
import { getSharedUsersAdminQuizById } from '../../api/getQuizzes';
import { isNotNull } from '@/utils/helpers';
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { ErrorBlock } from "@/components/ErrorBlock";
import Icon from '@/components/Icon';

type Props = {
    quiz: QuizType;
    isOpen?: boolean;
    onClose: () => void;
    onSubmit: (data: AssignQuizToUsersRequest) => void;
    errorMessage?: string;
    sharedList?: SharedListMyQuiz[] | SharedListAdminQuiz[] | null;
}

export const DistributeQuizModal: FC<Props> = ({ quiz, isOpen = false, onClose, onSubmit, errorMessage, sharedList }: Props) => {
    const { user } = useAuth();
    const { pathname } = useLocation();
    const isMyQuiz = pathname.includes('my-quiz');
    const isSuperAdmin = user?.roles.includes('ROLE_SUPER_ADMIN');

    const { data, isLoading, isError } = useQuery(
        ['distributeEmail', quiz.id, sharedList],
        () => !sharedList ? getSharedUsersAdminQuizById(quiz.id) : null,
        {
            enabled: isOpen,
        }
    );

    const [emails, setEmails] = useState<string[]>([]);
    const ref = useRef<HTMLTextAreaElement | null>(null);

    const handleSubmit = useCallback(async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        await onSubmit({
            emails: emails,
            isAdminQuiz: quiz?.quizType ? quiz.quizType === "adminQuiz" : isSuperAdmin,
        });

        onClose();
    }, [onSubmit, onClose, emails]);

    const handleRemoveClick = useCallback((email: string) =>(e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const newEmails = emails.filter((item) => item !== email)

        setEmails(newEmails);

        if (ref.current) {
            ref.current.value = newEmails?.join(', ') ?? '';
        }
    }, [emails]);

    const handleInputChange = useCallback((e:  React.ChangeEvent<HTMLTextAreaElement>) => {
        e.preventDefault();

        setEmails(e.target.value.match(/([a-zA-Z0-9-+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi) ?? []);
    }, []);

    useEffect(() => {
        if (isSuperAdmin && ref.current && !sharedList) {
            const oldEmails = data?.sharedList?.map((item) => item.user.email).filter(isNotNull);

            oldEmails && setEmails(oldEmails);
            ref.current.value = oldEmails?.join(', ') ?? '';
        } else if (sharedList?.length && ref.current) {
            const isAdminQuiz = quiz.quizType === "adminQuiz";
            const oldEmails = (isAdminQuiz && isSuperAdmin) || isMyQuiz
                ? (sharedList as SharedListAdminQuiz[]).map((item) => item?.user?.email)?.filter(isNotNull)
                : (sharedList as SharedListMyQuiz[]).map((item) => item?.email)?.filter(isNotNull);

            oldEmails && setEmails(oldEmails);
            ref.current.value = oldEmails?.join(', ') ?? '';
        }
    }, [data, isOpen, sharedList, isMyQuiz]);

    if (isError) return <ErrorBlock error={'Error'} textOnly />;

    return (
        <Modal isOpen={isOpen} onClose={onClose} size='3xl'>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Share your Quiz {quiz.title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Textarea
                        ref={ref}
                        defaultValue={''}
                        onChange={handleInputChange}
                        placeholder='Send Invitations'
                        size='md'
                    />
                    <Text
                        mt={'20px'}
                        mb={'20px'}
                        fontSize={'14px'}
                    >
                        Enter one email address per line or add all emails as a comma separated string.
                    </Text>

                    {emails.map((item, key) => (
                        <Box
                            key={key}
                            width={'100%'}
                            border={'1px solid #E4E4E4'}
                            borderRadius={'20px'}
                            mb={'20px'}
                            p={'10px'}
                            display={'flex'}
                            alignContent={'center'}
                        >
                            <Text fontSize={'14px'} width={'100%'}>
                                {item}
                            </Text>
                            <Button
                                h="auto"
                                display="block"
                                color="red"
                                p="0"
                                fontSize="12px"
                                lineHeight="12px"
                                fontWeight="normal"
                                bg="primary.grey"
                                _hover={{}}
                                _focus={{ outline: 0 }}
                                _active={{}}
                                onClick={handleRemoveClick(item)}
                            >
                                <Icon
                                    icon='trash-can'
                                    fontSize="20px"
                                />
                            </Button>
                        </Box>
                    ))}
                </ModalBody>

                {errorMessage &&
                    <Box p='10px 0 10px 25px' color='red'>
                        {errorMessage}
                    </Box>
                }

                <ModalFooter alignItems={'center'} justifyContent={'center'}>
                    <Button
                        type={'submit'}
                        variant='authBtn'
                        w={'100%'}
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        {isLoading ?
                            <LoadingSpinner />
                            :
                            'Send Quiz'
                        }
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
